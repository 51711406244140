<!-------------------------------------------------
description:  运营配置 用户黑名单 详情
/**
 *@author xiaosanye 
 *@date 2023/2/21
*/
--------------------------------------------------->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="content">
      <ykc-detail-plus :labelWidth="80">
        <div slot="header" class="clearfix">
          <span class="title">限制信息</span>
        </div>
        <ykc-detail-item-plus label="用户账号">
          {{ userAccount || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="限制站点">
          {{ limit || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="限制状态">
          {{ status === 1 ? '启用' : '停用' || '——' }}
        </ykc-detail-item-plus>
      </ykc-detail-plus>
      <ykc-table
        style="margin-top: 16px"
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        :title="tableTitle"
        :operateButtons="tableOperateButtons">
        <template #headerButtons>
          <ykc-button
            type="plain"
            @click="doExportFile2"
            v-rbac="'operation:config:black:download'">
            导出
          </ykc-button>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="total"
          :current-page.sync="currentPage"
          @current-change="handlePaginationCurrentChange"
          :page-size.sync="pageSize"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { accountManage, operationsUserDenyList } from '@/service/apis';
  import { localGetItem, offlineExport, sessionGetItem } from '@/utils';

  export default {
    name: 'SpecialSellingPriceDetail',
    components: {},
    data() {
      return {
        dictionary: {},
        status: '',
        id: '',
        limit: '',
        userAccount: '',
        tableData: [{}],

        tableColumns: [
          {
            label: '电站编码',
            prop: 'stationId',
            scopedSlots: {
              default: ({ row }) => {
                return (
                  <span class="link" onClick={() => {}} title={row.stationId}>
                    {row.stationId}
                  </span>
                );
              },
            },
          },
          { label: '电站名称', prop: 'name' },
          {
            label: '电站类型',
            prop: 'type',
            scopedSlots: {
              default: ({ row }) => {
                const title =
                  this.dictionary?.find(o => Number(o.id) === Number(row.type))?.name ?? '——';
                return <span>{title}</span>;
              },
            },
          },
          { label: '归属商户', prop: 'operatorName' },
        ],
        tableTitle: '限制站点列表',

        tableOperateButtons: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
        treeData: [],
        treeProps: {
          label: 'roleName',
          children: 'subNodes',
          disabled: 'disabled',
        },
        formData: {},
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        accountId: '',
        accountNo: '',
        roleType: '',
      };
    },
    computed: {
      ...mapGetters(['getDenyListCurrentStationIds']),
    },
    created() {
      const { status, id, limit, userAccount } = this.$route.query;
      this.status = status;
      this.id = id;
      this.limit = limit;
      this.userAccount = userAccount;
      const dictionary = localGetItem('dictionary');
      if (dictionary?.station_type) {
        this.dictionary = dictionary.station_type.map(item => ({
          id: item.code,
          name: item.name,
        }));
      }
    },
    mounted() {
      this.getListData();
    },
    methods: {
      roleTypeFormat(row) {
        const [property] = localGetItem('dictionary').role_type.filter(item => item.code === row);
        return property ? property.name : '——' || '——';
      },
      getDetail() {
        accountManage.detail(this.accountId).then(res => {
          this.formData = res;
          this.treeData = res?.roleList || [];
        });
      },
      // 导出 账号属性
      doExportFile() {
        console.log('doExportFile');

        offlineExport(
          {
            downloadType: 'account_menu',
            jsonNode: {
              accountId: this.accountId,
            },
          },
          this.total
        );
      },
      // 导出 数据权限
      doExportFile2() {
        console.log('doExportFile2');
        offlineExport(
          {
            downloadType: 'user_black_list_station_export',
            jsonNode: {
              stationIdList: sessionGetItem('denyListCurrentStationIds'),
            },
          },
          this.total
        );
      },
      getListData() {
        const reqParams = {
          stationIdList: sessionGetItem('denyListCurrentStationIds'),
          current: this.currentPage,
          size: this.pageSize,
        };
        operationsUserDenyList.stationLIst(reqParams).then(res => {
          this.tableData = res?.records || [];
          this.total = res.total;
        });
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.currentPage = current;
        this.getListData();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.currentPage = 1;
        this.pageSize = size;
        this.getListData();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    // display: flex;
    .clearfix {
      height: 19px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 19px;
    }
    .box-card {
      margin: 0;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .status {
        margin-left: 10px;
        color: #fff;
        height: 20px;
        border-radius: 10px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        line-height: 20px;
        &.stationStatus {
          &.status1000135 {
            background-color: #009f09;
          }
          &.status1000136 {
            background-color: #e81b1b;
          }
        }
        &.infoStatus {
          &.status1 {
            background-color: #009f09;
          }
          &.status2 {
            background-color: #ccc;
            color: #fff;
          }
          &.status0 {
            background-color: #e81b1b;
          }
        }
      }
      ::v-deep .el-form-item {
        display: flex;
        align-items: center;
      }
      table {
        width: calc(100% - 30px);
        border-left: 1px solid #ddd;
        border-top: 1px solid #ddd;
        margin-left: 30px;
        margin-top: 20px;
        th {
          background-color: #f6f7f8;
          font-weight: bold;
        }
        th,
        td {
          font-size: 12px;
          text-align: left;
          padding: 0 10px;
          line-height: 40px;
          height: 40px;
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          ::v-deep .el-form-item {
            margin-bottom: 0;
            input {
              width: 100px;
            }
          }
        }
      }
    }
  }
  .link {
    color: blue;
    cursor: pointer;
  }
</style>
